<template src="./index.html" />

<script>
import {
  BButton, BCol, BRow, BForm, BBreadcrumb, BCard, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line import/extensions
import FormSchedule from '@/views/schedule/FormSchedule'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  name: 'Schedule',
  components: {
    BButton,
    BCol,
    BRow,
    BForm,
    BBreadcrumb,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    vSelect,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    FormSchedule,
  },
  props: {

  },
  data() {
    return {
      user: getUser(),
      table: {
        fields: [
          {
            field: 'rowNum', label: 'STT', sortable: false, type: 'string',
          },
          {
            field: 'creditClassCode', label: 'Lớp độc lập', sortable: false, type: 'string',
          },
          {
            field: 'subjectName', label: 'Học phần', sortable: false, type: 'string',
          },
          {
            field: 'schedule', label: 'Lịch học (Thứ - Phòng - Tiết - Giảng viên)', sortable: false, type: 'string',
          },
          {
            field: 'action', tdClass: 'text-center', thClass: 'text-center', label: 'Hành động', sortable: false,
          },
        ],
        items: [],
      },
      itemsDisplay: [],
      totalRecords: 0,
      loading: true,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      dataSendForm: {
        isCreated: 0,
      },
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        trainingSystemId: '',
        courseId: '',
        courseSemesterId: '',
        departmentId: '',
        subjectId: '',
        creditClassId: '',
        teacherId: '',
        dayId: '',
        status: 1,
        maxStatus: 3,
        sort: '',
      },
    }
  },
  async created() {
    await Promise.all([
      this.getTrainingSystems(),
      this.getDepartments(),
      this.getDays(),
    ])
    if (this.trainingSystems.length > 0) {
      this.filter.trainingSystemId = this.trainingSystems[0].value
      await this.getCourses(this.filter)
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      schedules: 'schedule/schedules',
      total: 'schedule/total',
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      courseSemesters: 'dropdown/courseSemesters',
      departments: 'dropdown/departments',
      subjects: 'dropdown/subjects',
      creditClasses: 'dropdown/creditClasses',
      teachers: 'dropdown/teachers',
      days: 'dropdown/days',
    }),
  },
  watch: {
    // listening change data init

  },
  methods: {
    ...mapActions({
      createSchedule: 'schedule/createSchedule',
      updateSchedule: 'schedule/updateSchedule',
      getSchedules: 'schedule/getSchedules',
      deleteSchedule: 'schedule/deleteSchedule',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getDepartments: 'dropdown/getDepartments',
      getSubjects: 'dropdown/getSubjects',
      getCreditClasses: 'dropdown/getCreditClasses',
      getTeachers: 'dropdown/getTeachers',
      getDays: 'dropdown/getDays',
    }),
    // handle even
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    openModal() {
      this.dataSendForm = {}
      this.$bvModal.show('modal-schedule')
    },
    // load items is what brings back the rows from server
    loadItems() {
      this.btnSearchClick()
    },
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
    async deleteItem(item) {
      this.$swal
        .fire({
          title: 'Xóa bản ghi này?',
          text: 'Bạn sẽ không thể khôi phục dữ liệu này sau khi xóa !',
          confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
          cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        .then(async result => {
          if (result.isConfirmed) {
            // eslint-disable-next-line no-shadow
            const result = await this.deleteSchedule(item.creditClassId)
            if (result) {
              await this.getMainDataFromStore()
              this.showToast('Đã xóa thành công!', 'CheckIcon','success', '')
            } else {
              this.showToast('Có lỗi xảy ra!', 'XCircleIcon','danger', '')
            }
          }
        })
    },
    async edit(data) {
      this.dataSendForm = data
      this.$bvModal.show('modal-schedule')
    },
    async onTrainingSystemsChange(event) {
      this.filter.trainingSystemId = event
      this.filter.courseId = null
      await this.getCourses(this.filter)
    },
    async onCoursesChange(event) {
      this.filter.courseId = event
      this.filter.courseSemesterId = null
      await this.getCourseSemesters({ courseId: this.filter.courseId })
    },
    async onDepartmentChange(event) {
      this.filter.departmentId = event
      this.filter.subjectId = null
      await this.getSubjects(this.filter)
    },
    async onSubjectChange(event) {
      this.filter.creditClassId = null
      await this.getCreditClasses(this.filter)
    },
    async onCreditClassChange(event) {
      this.filter.teacherId = null
      await this.getTeachers(this.filter)
    },
    async saveDataForm(data) {
      const objData = {
        creditClassId: data.creditClassId,
        creditClassRoomId: data.creditClassRoomId,
        organizationId: data.organizationId,
        teacherId: data.teacherId,
        listDayCheck: data.listDayCheck,
        beginDate: data.beginDate,
        weeks: data.weeks,
      }
      // return;
      const res = await this.createSchedule(objData)
      if (res.code == 1) {
        this.$bvModal.hide('modal-schedule')
        this.showToast(res.message, 'CheckIcon','success', '')
      } else {
        this.showToast(res.message, 'XCircleIcon','danger', '')
      }
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getSchedules(this.filter)
        this.schedules.forEach((item, index) => {
          const days = item._days.split('___')
          days.splice(-1)
          const dayIds = item._dayIds.split('___')
          dayIds.splice(-1)
          const rooms = item._rooms.split('___')
          rooms.splice(-1)
          const teachers = item._teacherNames.split('___')
          teachers.splice(-1)
          const turns = item._turns.split('___')
          turns.splice(-1)
          const turnIds = item._turnIds.split('___')
          turnIds.splice(-1)
          let roomArr = []
          let teacherArr = []
          let turnArr = []
          let schedule = '<ul>'
          const dayTurns = []
          for (let i = 0; i < days.length; i++) {
            const dayTurn = {}
            dayTurn.dayId = dayIds[i].replace(',', '')
            schedule += `<li>${days[i].replace(',', '')}: <ul>`
            roomArr = rooms[i].split('|')
            roomArr.splice(-1)
            teacherArr = teachers[i].split('|')
            // teachers.splice(-1);
            turnArr = turns[i].split('|')

            // turnArr.splice(-1);
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < roomArr.length; j++) {
              turnArr[j] = turnArr[j].substr(0, 1).replace(',', '')
                  + turnArr[j].substring(1)
              schedule += `<li class="border border-info">&#9962; [<strong>${roomArr[j].replace(',', '')}</strong>] &#9200; [<strong>${turnArr[j]}</strong>] &#9997; [<strong>${teacherArr[j].replace(',', '')}</strong>]</li>`
            }
            let turnItem = []
            const turnItemIds = turnIds[i].split('|')
            turnItemIds.splice(-1)

            turnItem = turnItemIds[0].split(',')
            // eslint-disable-next-line no-shadow
            turnItem = turnItem.filter(item => item != '')
            dayTurn.turnIds = turnItem
            schedule += '</ul></li>'
            dayTurns.push(dayTurn)
          }
          schedule += '</ul>'
          this.schedules[index].schedule = schedule
          this.schedules[index].dayTurns = dayTurns
          // item.schedule = "123";
        })

        this.table.items = this.schedules
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
